import CompanyRecieptlist from "../../../components/travelCrmReports/companyRecieptlist/CompanyRecieptlist"



const CompanyRecieptlistPage = () => {
    return (
        <>
            <CompanyRecieptlist />
        </>
    )
}

export default CompanyRecieptlistPage