import BillPaymentReminderReport from "../../../components/travelCrmReports/billPaymentReminderReport/BillPaymentReminderReport"


const BillPaymentReminderReportPage = () => {
    return (
        <>
            <BillPaymentReminderReport />
        </>
    )
}

export default BillPaymentReminderReportPage