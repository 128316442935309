import TravelInsurence from "../../../components/travelCrmMaster/travelInsurence/TravelInsurence"


const TravelInsurencePage = () => {
    return (
        <>
            <TravelInsurence />
        </>
    )
}

export default TravelInsurencePage