import React from 'react'
import TransportVoucherReports from '../../../components/travelCrmReports/transportVoucherReport/TranportVoucherReports'

function TransportVoucher() {
    return (
        <>
            <TransportVoucherReports />
        </>
    )
}

export default TransportVoucher