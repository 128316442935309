import React from 'react'
import ProductionInformation from '../../components/cropProductions/productionInformation/ProductionInformation'

const Production = () => {
  return (
    <div>
        <ProductionInformation />
    </div>
  )
}

export default Production