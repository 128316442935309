import React from 'react'
import LrAdd from '../../components/lrAdd/LrAdd'

function LrAddPage() {
    return (
        <>
            <LrAdd />
        </>
    )
}

export default LrAddPage