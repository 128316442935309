import React from 'react'
import BirthdayAndAnniversary from '../../../components/travelCrmReports/birthdayandaniverReport/BirthdayAndAnniversery'

function BirthdatAnniversary() {
    return (
        <>
            <BirthdayAndAnniversary />
        </>
    )
}

export default BirthdatAnniversary