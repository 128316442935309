export const baseUrl = 'https://backoffice.adamclick.com/api/'

export const baseproductUrl = 'https://product.adamclick.com/api/'

export const baseUrlImage = 'https://backoffice.adamclick.com/api/cloudinary/'
// export const baseUrVideosbefore = 'https://collegebazaar.cityonline.shop/api/cloudinary/video/'
export const baseUrVideos = 'https://collegebazaar.cityonline.shop/api/cloudinary/video/'
// export const baseUrLKycVideos = 'https://collegebazaar.cityonline.shop/api/cloudinary/addVideo/'

export const extraBaseUrl = 'https://fintech.adamclick.com/api/'
