import LeadWise from "../../../components/travelCrmReports/leadWise/LeadWise"


const LeadWisePage = () => {
    return (
        <>
            <LeadWise />
        </>
    )
}

export default LeadWisePage