import Tags from "../../../components/manageTravelBussiness/tags/Tags"


const TagsPage = () => {
    return (
        <>
            <Tags />
        </>
    )
}

export default TagsPage