import LeadFollowUpCalls from "../../../components/travelCrmReports/leadFollowUpCalls/LeadFollowUpCalls"


const LeadFollowUpCallsPage = () => {
    return (
        <>
            <LeadFollowUpCalls />
        </>
    )
}

export default LeadFollowUpCallsPage