import SuplierPaidReport from "../../../components/travelCrmReports/suplierPaidReport/SuplierPaidReport"


const SuplierPaidReportPage = () => {
    return (
        <>
            <SuplierPaidReport />
        </>
    )
}

export default SuplierPaidReportPage