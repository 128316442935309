import React from 'react'
import FullLoadList from '../../components/fullLoadList/FullLoadList'

function FullLoadListPage() {
    return (
        <>
            <FullLoadList />
        </>
    )
}

export default FullLoadListPage