import React from 'react'
import AllCustomerReports from '../../../components/travelCrmReports/allCustomerReport/AllCustomerReports'

function AllCustomer() {
    return (
        <>
            <AllCustomerReports />
        </>
    )
}

export default AllCustomer