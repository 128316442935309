import LoginHistoryy from "../../../components/travelCrmReports/loginHistory/loginHistoryy"

const LoginHistoryyPage = () => {
    return (
        <>
            <LoginHistoryy />
        </>
    )
}

export default LoginHistoryyPage
