import React from 'react'
import GpsFlaginformation from '../../components/trackerList/gpsFlaginformation/GpsFlaginformation'

const GpsFlag = () => {
  return (
    <div>
        <GpsFlaginformation />
    </div>
  )
}

export default GpsFlag