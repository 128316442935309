import React from 'react'
import LiveFeedList from '../../components/trackerList/liveFeedList/LiveFeedList'

const LiveFeed = () => {
  return (
    <div>
        <LiveFeedList />
    </div>
  )
}

export default LiveFeed