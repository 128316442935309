import SupplierPurchage from "../../../components/travelCrmReports/supplierPurchage/SupplierPurchage"

const SupplierPurchagePage = () => {
    return (
        <>
            <SupplierPurchage />
        </>
    )
}

export default SupplierPurchagePage