import CustomerCallsStatus from "../../../components/travelCrmReports/customerCallsStatus/CustomerCallsStatus"


const CustomerCallsStatusPage = () => {
    return (
        <>
            <CustomerCallsStatus />
        </>
    )
}

export default CustomerCallsStatusPage