import React from 'react'
import Boost from '../../components/boost/Boost'

const Boosts = () => {
  return (
    <div>
        <Boost />
    </div>
  )
}

export default Boosts