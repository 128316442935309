import TravelProfitLoss from "../../../components/travelCrmReports/profitLoss/TravelProfitLoss"



const TravelProfitLossPage = () => {
    return (
        <TravelProfitLoss />
    )
}

export default TravelProfitLossPage