import CompanyInvoicelist from "../../../components/travelCrmReports/companyInvoicelist/CompanyInvoicelist"

const CompanyInvoicelistPage = () => {
    return (
        <>
            <CompanyInvoicelist />
        </>
    )
}

export default CompanyInvoicelistPage