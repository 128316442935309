import B2bInvoice from "../../../components/travelCrmReports/b2bInvoice/B2bInvoice"



const B2bInvoicePage = () => {
    return (
        <>
            <B2bInvoice />
        </>
    )
}

export default B2bInvoicePage