import ServiceeMasterr from "../../../components/travelCrmMaster/serviceMaster/ServiceeMasterr"


const ServiceeMasterrPage = () => {
    return (
        <>
            <ServiceeMasterr />
        </>
    )
}

export default ServiceeMasterrPage