import SalespersonWise from "../../../components/travelCrmReports/salespersonWise/SalespersonWise"


const SalesPersonWisePage = () => {
    return (
        <>

            <SalespersonWise />
        </>
    )
}

export default SalesPersonWisePage