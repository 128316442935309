import Sightseeing from "../../../components/manageTravelBussiness/sightseeing/Sightseeing"



const SightseeingPage = () => {
    return (
        <>
            <Sightseeing />
        </>
    )
}

export default SightseeingPage