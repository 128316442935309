import React from 'react'
import ManageCustomerInside from '../../components/customerInside/manageCustomerInside/ManageCustomerInside'

const ManageCustomer = () => {
  return (
    <div>
    <ManageCustomerInside /> 
    </div>
  )
}

export default ManageCustomer