import React from 'react'
import FullLoadAdd from '../../components/fullLoadAdd/FullLoadAdd'

function FullLoadAddPage() {
    return (
        <>
            <FullLoadAdd />
        </>
    )
}

export default FullLoadAddPage