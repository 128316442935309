import TravelVisaMaster from "../../../components/travelCrmMaster/travelVisaMaster/TravelVisaMaster"


const TravelVisaMasterPage = () => {
    return (
        <>
            <TravelVisaMaster />
        </>
    )
}

export default TravelVisaMasterPage