import ZomatoDashboard from "../../components/zomatoDashboard/ZomatoDashboard"


const ZomatoDashboardPage = () => {
    return (
        <>
            <ZomatoDashboard />
        </>
    )
}

export default ZomatoDashboardPage