import TravelForex from "../../../components/travelCrmMaster/travelForex/TravelForex"


const TravelForexPage = () => {
    return (
        <>
            <TravelForex />
        </>
    )
}

export default TravelForexPage