import ResturentDasbord from "../../components/resturentDasbord/ResturentDasbord"


const ResturentDasboardPage = () => {
    return (
        <>
            <ResturentDasbord />
        </>
    )
}

export default ResturentDasboardPage