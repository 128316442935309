import CustomerType from "../../../components/travelCrmMaster/customerType/CustomerType"


const CustomerTypePage = () => {
    return (
        <>
            <CustomerType />
        </>
    )
}

export default CustomerTypePage