import React from 'react'
import HotelVoucher from '../../../components/travelCrmReports/hotelVoucher/HotelVoucher'

function HotelVoucherPage() {
    return (
        <>
            <HotelVoucher />
        </>
    )
}

export default HotelVoucherPage