import React from 'react'
import CropHealthInformation from '../../components/cropProductions/cropHealthInformation/CropHealthInformation'

const CropHealthCodes = () => {
  return (
    <div>
    <CropHealthInformation />
    </div>
  )
}

export default CropHealthCodes