import ContactWise from "../../../components/travelCrmReports/contactWise/ContactWise"


const ContactWisePage = () => {
    return (
        <>
            <ContactWise />
        </>
    )
}

export default ContactWisePage