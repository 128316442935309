import TravelVisaCategory from "../../../components/travelCrmMaster/travelVisaCategory/TravelVisaCategory"

const TravelVisaCategoryPage = () => {
    return (
        <>
            <TravelVisaCategory />
        </>
    )
}

export default TravelVisaCategoryPage