

import TaskReminders from '../../../components/travelCrmReports/taskReminders/TaskReminders'

const TaskRemindersPage = () => {
    return (
        <>
            <TaskReminders />
        </>
    )
}

export default TaskRemindersPage