import AllLeadWiseHistory from "../../../components/travelCrmReports/allLeadWiseHistory/AllLeadWiseHistory"

const AllLeadWiseHistoryPage = () => {
    return (
        <>
            <AllLeadWiseHistory />
        </>
    )
}

export default AllLeadWiseHistoryPage