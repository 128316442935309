import React from 'react'
import TravelPackageAddPage from '../travelPackageAddPage'

function TravelPackageEditPage() {
    return (
        <>
            <TravelPackageAddPage />
        </>
    )
}

export default TravelPackageEditPage