import B2cInvoice from "../../../components/travelCrmReports/b2cInvoice/B2cInvoice"


const B2cInvoicePage = () => {
    return (
        <>
            <B2cInvoice />
        </>
    )
}

export default B2cInvoicePage