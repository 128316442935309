import React from 'react'
import DegreeView from '../../components/degreeView/DegreeView'

const DegreeViews = () => {
  return (
    <div>
        <DegreeView />
    </div>
  )
}

export default DegreeViews