import Travelvouchers from "../../../components/travelLeads/Travelvouchers/Travelvouchers"


const TravelVoucherPage = () => {
    return (
        <>
            <Travelvouchers />
        </>
    )
}

export default TravelVoucherPage