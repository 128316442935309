import GrowComp from "../../../components/transaction/godown-stock/GrowComp"


const GrowDownPage = () => {
    return (
        <>
            <GrowComp />
        </>
    )
}

export default GrowDownPage