import React from 'react'
import TrendView from '../../components/trendView/TrendView';

const TrendViews = () => {
  return (
    <div>
        <TrendView />
    </div>
  )
}

export default TrendViews;