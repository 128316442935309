import TravelClass from "../../../components/travelCrmMaster/travelClass/TravelClass"


const TravelClassPage = () => {
    return (
        <>
            <TravelClass />
        </>
    )
}

export default TravelClassPage