import LRRegistComp from "../../../../components/transaction/LRregister/LRRegistComp"


const LRRegisterPage = () => {
    return (
        <>
            <LRRegistComp />
        </>
    )
}

export default LRRegisterPage