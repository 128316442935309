import React from 'react'
import FlightVoucherReports from '../../../components/travelCrmReports/flightVoucherReports/FlightVoucherReports'

function FlightVoucher() {
    return (
        <>
            <FlightVoucherReports />
        </>
    )
}

export default FlightVoucher