import TravelVisaType from "../../../components/travelCrmMaster/travelVisaType/TravelVisaType"


const TravelVisaTypePage = () => {
    return (
        <>
            <TravelVisaType />
        </>
    )
}

export default TravelVisaTypePage