import React from 'react'
import AgronomyVisitInfomation from '../../components/cropProductions/agronomyVisitInfomation/AgronomyVisitInfomation'

const AgronomyVisit = () => {
  return (
    <div>
        <AgronomyVisitInfomation />
    </div>
  )
}

export default AgronomyVisit