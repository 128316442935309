import React from 'react'
import CropMasterInformation from '../../components/cropProductions/cropMasterInformation/CropMasterInformation'

const CropMaster = () => {
  return (
    <div>
        <CropMasterInformation />
    </div>
  )
}

export default CropMaster