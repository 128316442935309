import React from 'react'
import OrderLR from '../../components/orderLR/OrderLR'

const Orderslr = () => {
  return (
    <div>
        <OrderLR />
    </div>
  )
}

export default Orderslr