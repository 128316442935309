import CallByUser from "../../../components/travelCrmReports/callByUser/CallByUser"


const CallByUserPage = () => {
    return (
        <>
            <CallByUser />
        </>
    )
}

export default CallByUserPage