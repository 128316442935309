import Salesperson from "../../../components/travelCrmReports/salesperson/Salesperson"



const SalesPersonPage = () => {
    return (
        <>
            <Salesperson />
        </>
    )
}

export default SalesPersonPage