import AllHotle from "../../../components/manageTravelBussiness/hotel/AllHotle"


const AllHotalPage = () => {
    return (
        <>
            <AllHotle />
        </>
    )
}

export default AllHotalPage