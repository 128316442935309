import React from 'react'
import DeliveryOfLrList from '../../components/deliveryOfLrList/DeliveryOfLrList'

function DeliveryOfLrListPage() {
    return (
        <>
            <DeliveryOfLrList />
        </>
    )
}

export default DeliveryOfLrListPage