import React from 'react'
import LrList from '../../components/lrList/LrList'

function LrListPage() {
    return (
        <>
            <LrList />
        </>
    )
}

export default LrListPage