import CancleReport from "../../../components/travelCrmReports/cancleReport/CancleReport"


const CancleReportPage = () => {
    return (
        <>
            <CancleReport />
        </>
    )
}

export default CancleReportPage