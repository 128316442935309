import BusMaster from "../../../components/travelCrmMaster/busMaster/BusMaster"


const BusMasterPage = () => {
    return (
        <>
            <BusMaster />
        </>
    )
}

export default BusMasterPage