import Rating from "../../../components/masterTraval/rating/Rating"


const RatingPage = () => {
    return (
        <>
            <Rating />
        </>
    )
}

export default RatingPage