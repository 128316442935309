import OtherMaster from "../../../components/travelCrmMaster/otherMaster/OtherMaster"


const OtherMasterPage = () => {
    return (
        <>
            <OtherMaster />
        </>
    )
}

export default OtherMasterPage