import TravelPassport from "../../../components/travelCrmMaster/travelPassport/TravelPassport"


const TravelPasswordPage = () => {
    return (
        <>
            <TravelPassport />
        </>
    )
}

export default TravelPasswordPage