import ContactReportUserWise from "../../../components/travelCrmReports/contactReportUserWise/ContactReportUserWise"

const ContactReportUserWisePage = () => {
    return (
        <>
            <ContactReportUserWise />
        </>
    )
}

export default ContactReportUserWisePage