import RoomCategory from "../../../components/travelCrmMaster/roomCategory/RoomCategory"


const RoomCategoryPage = () => {
    return (
        <>
            <RoomCategory />
        </>
    )
}

export default RoomCategoryPage