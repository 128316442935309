import TravelCruise from "../../../components/travelCrmMaster/cruise/TravelCruise"


const TravelCruisePage = () => {
    return (
        <>
            <TravelCruise />
        </>
    )
}

export default TravelCruisePage