import LeadCreatedWise from "../../../components/travelCrmReports/leadCreatedWise/LeadCreatedWise"


const LeadCreatedWisePage = () => {
    return (
        <>
            <LeadCreatedWise />
        </>
    )
}

export default LeadCreatedWisePage