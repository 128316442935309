import React from 'react'
import DealerStockInside from '../../components/customerInside/manageStockInside/dealerStockInside/DealerStockInside'

const DealarStock = () => {
  return (
    <div>
        <DealerStockInside />
    </div>
  )
}

export default DealarStock