import TravelPreference from "../../../components/travelCrmMaster/travelPreference/TravelPreference"


const TravelPreferencePage = () => {
    return (
        <>
            <TravelPreference />
        </>
    )
}

export default TravelPreferencePage