import CustomerPaymentReport from "../../../components/travelCrmReports/customerPaymentReport/CustomerPaymentReport"



const CustomerPaymentReportPage = () => {
    return (
        <>
            <CustomerPaymentReport />
        </>
    )
}

export default CustomerPaymentReportPage