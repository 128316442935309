import React from 'react'
import TrackingList from '../../components/trackerList/trackingList/TrackingList'

const Tracking = () => {
  return (
    <div>
    <TrackingList />
    </div>
  )
}

export default Tracking