import React from 'react'
import ProductStockInside from '../../components/customerInside/manageStockInside/productStockInside/ProductStockInside'

const ProductStock = () => {
  return (
    <div>
        <ProductStockInside />
    </div>
  )
}

export default ProductStock