import TripType from "../../../components/travelCRM/tripType/TripType"


const TripTypePage = () => {
    return (
        <>
            <TripType />
        </>
    )
}

export default TripTypePage