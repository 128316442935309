
import MiscellaneousReport from "../../../components/travelCrmReports/miscellaneous/Miscellaneous"



const MiscellaneousPage = () => {
    return (
        <>
            <MiscellaneousReport />
        </>
    )
}

export default MiscellaneousPage