import CustomerSaleReport from "../../../components/travelCrmReports/customerSaleReport/CustomerSaleReport"


const CustomerSaleReportPage = () => {
    return (
        <>
            <CustomerSaleReport />
        </>
    )
}

export default CustomerSaleReportPage