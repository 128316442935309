

import { OrderReturnFom } from "../../../components/Empoyee/OrderReturn/NewOrderReturn";

const NewOrderReturn = () => {
    return (
        <div>
            <OrderReturnFom />
        </div>
    )
}
export default NewOrderReturn