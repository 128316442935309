import React from 'react'
import MemoEntryList from '../../components/memoEntryListComp/MemoEntryList'

function MemoEntryListPage() {
    return (
        <>
            <MemoEntryList />
        </>
    )
}

export default MemoEntryListPage