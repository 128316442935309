import TravelLeads from "../../components/travelLeads/travelLeads/TravelLeads"



const TravelLeadPage = () => {
    return (
        <>
            <TravelLeads />
        </>
    )
}

export default TravelLeadPage