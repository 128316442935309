import React from 'react'
import EmployeeTrackingInformation from '../../components/trackerList/employeeTrackingInformation/EmployeeTrackingInformation'

const RrackingReport = () => {
  return (
    <div>
    <EmployeeTrackingInformation />
    </div>
  )
}

export default RrackingReport