import TravelAdventure from "../../../components/travelCrmMaster/travelAdventure/TravelAdventure"


const TravelAdventurePage = () => {
    return (
        <>
            <TravelAdventure />
        </>
    )
}

export default TravelAdventurePage