import FareType from "../../../components/travelCrmMaster/fareType/FareType"

const FareTypePage = () => {
    return (
        <>
            <FareType />
        </>
    )
}

export default FareTypePage