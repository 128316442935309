import UserHistory from "../../../components/travelCrmReports/userHistory/UserHistory"


const UserHistoryPage = () => {
    return (
        <>
            <UserHistory />
        </>
    )
}

export default UserHistoryPage