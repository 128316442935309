import React from 'react'
import AllReportMain from '../../components/allReportMain/AllReportMain'

const AllReports = () => {
  return (
    <div>
    <AllReportMain />
    </div>
  )
}

export default AllReports