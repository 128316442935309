import HotelType from "../../../components/masterTraval/hotelType/HotelType"


const HotelTypePage = () => {
    return (
        <>
            <HotelType />
        </>
    )
}

export default HotelTypePage