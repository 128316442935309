import React from 'react'
import PayableList from '../../components/travelCrmReports/payble/PayableList'
function AllPayablePage() {
    return (
        <>
            <PayableList/>
        </>
    )
}

export default AllPayablePage