import React from 'react'
import InvoiceRecieptReports from '../../../components/travelCrmReports/invoiceRecieptReport/InvoiceRecieptReports'

function InvouceReciept() {
    return (
        <>
            <InvoiceRecieptReports />
        </>
    )
}

export default InvouceReciept