import PassportVisaExpiry from "../../../components/travelCrmReports/passportVisaExpiry/PassportVisaExpiry"

const PassportVisaExpiryPage = () => {
    return (
        <>
            <PassportVisaExpiry />
        </>
    )
}

export default PassportVisaExpiryPage