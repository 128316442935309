import React from 'react'
import DeliveryOfLrAdd from '../../components/deliveryOfLrAdd/DeliveryOfLrAdd'

function DeliveryOfLrAdPage() {
    return (
        <>
            <DeliveryOfLrAdd />
        </>
    )
}

export default DeliveryOfLrAdPage