import AgentMaster from "../../../components/travelCrmMaster/agentMaster/AgentMaster"


const AgentMasterPage = () => {
    return (
        <>
            <AgentMaster />
        </>
    )
}

export default AgentMasterPage