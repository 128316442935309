import RoomType from "../../../components/travelCrmMaster/roomType/RoomType"


const RoomTypePage = () => {
    return (
        <>
            <RoomType />
        </>
    )
}

export default RoomTypePage